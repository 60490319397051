import React from "react";
import "./Footer.css";
import { Trans, Link, useTranslation } from "gatsby-plugin-react-i18next";

// const EN = () => (
//   <div className="container-fluid">

//   <div className="row">

//     <div className="col-sm-12 col-md-4">
//       <h3 className="text-white mb-2"> Explorer </h3>
//       <ul className="list-unstyled">
//         <li className="mb-2">
//         <li className="mb-2">
//           <Link to="/collections/films-gratuits/" className="text-light">
//             Films gratuits
//           </Link>
//         </li>
//           <Link to="/collections/vieux-films-gratuits/" className="text-light">
//             Vieux films gratuits en Français
//           </Link>
//         </li>
//         <li className="mb-2">
//           <Link to="/collections/documentaires-gratuits/" className="text-light">
//             Documentaires gratuits en Français
//           </Link>
//         </li>

//         <li className="mb-2">
//           <Link to="/collections/dessins-animes-gratuits/" className="text-light">
//             Dessins animés gratuits
//           </Link>
//         </li>
//       </ul>

//     </div>
//     <div className="col-sm-12 col-md-4">
//         <h3>
//           <Link to="/blog/" className="text-white">Blog</Link>
//         </h3>
//         <ul className="list-unstyled">
//           <li className="mb-2">
//             <Link to="/blog/films-gratuits/" className="text-light">
//               165 Films Gratuits en Français à Voir en Streaming
//             </Link>
//           </li>

//           <li className="mb-2">
//             <Link to="/blog/dessins-animes-gratuits/" className="text-light">
//               97 Dessins Animés Gratuits
//             </Link>
//           </li>
//         </ul>

//     </div>

//     <div className="col-sm-12 col-md-4 pr-0">
//       <h3 className="text-white mb-2"> Pages </h3>
//       <ul className="list-unstyled">

//           {/* <li className="mb-2">
//             <a className="text-light" href="https://cinetimes.org/apropos/">
//               À propos
//             </a>
//           </li> */}
//           <li className="mb-2">
//             <a className="text-light" href="/help/mentions-legales/">
//               Mentions légales et politique de confidentialité
//             </a>
//           </li>
//           <li className="mb-2">
//             <a className="text-light" href="/help/faq/">
//               F.A.Q
//             </a>
//           </li>
//           <li className="mb-2">
//             <a className="text-light" href="/help/copyright/">
//               Droits d'auteur
//             </a>
//           </li>
//           {/* <li className="mb-2" dangerouslySetInnerHTML = {{
//             __html: `
//               <a class="change-consent text-light" onclick="window.__cmp('displayConsentUi');">
//                 Paramètres de Gestion de la Confidentialité
//               </a>
//             `
//           }}>
//           </li> */}
//           <li className="mb-2">
//               {/* <a className="change-consent text-light" onClick={() => window.__cmp('displayConsentUi')}>
//                 Paramètres de Gestion de la Confidentialité
//               </a> */}
//               <a className="text-light" href="javascript: Cookiebot.renew()">
//                 Paramètres de Cookies et Gestion de la Confidentialité
//               </a>
//           </li>

//       </ul>
//     </div>
//   </div>
// </div>
// )
// const ES = () => (
//   <div className="container-fluid">

//   <div className="row">

//     <div className="col-sm-12 col-md-4">
//       <h3 className="text-white mb-2"> Explorer </h3>
//       <ul className="list-unstyled">
//         <li className="mb-2">
//         <li className="mb-2">
//           <Link to="/collections/films-gratuits/" className="text-light">
//             Films gratuits
//           </Link>
//         </li>
//           <Link to="/collections/vieux-films-gratuits/" className="text-light">
//             Vieux films gratuits en Français
//           </Link>
//         </li>
//         <li className="mb-2">
//           <Link to="/collections/documentaires-gratuits/" className="text-light">
//             Documentaires gratuits en Français
//           </Link>
//         </li>

//         <li className="mb-2">
//           <Link to="/collections/dessins-animes-gratuits/" className="text-light">
//             Dessins animés gratuits
//           </Link>
//         </li>
//       </ul>

//     </div>
//     <div className="col-sm-12 col-md-4">
//         <h3>
//           <Link to="/blog/" className="text-white">Blog</Link>
//         </h3>
//         <ul className="list-unstyled">
//           <li className="mb-2">
//             <Link to="/blog/films-gratuits/" className="text-light">
//               165 Films Gratuits en Français à Voir en Streaming
//             </Link>
//           </li>

//           <li className="mb-2">
//             <Link to="/blog/dessins-animes-gratuits/" className="text-light">
//               97 Dessins Animés Gratuits
//             </Link>
//           </li>
//         </ul>

//     </div>

//     <div className="col-sm-12 col-md-4 pr-0">
//       <h3 className="text-white mb-2"> Pages </h3>
//       <ul className="list-unstyled">

//           {/* <li className="mb-2">
//             <a className="text-light" href="https://cinetimes.org/apropos/">
//               À propos
//             </a>
//           </li> */}
//           <li className="mb-2">
//             <a className="text-light" href="/help/mentions-legales/">
//               Mentions légales et politique de confidentialité
//             </a>
//           </li>
//           <li className="mb-2">
//             <a className="text-light" href="/help/faq/">
//               F.A.Q
//             </a>
//           </li>
//           <li className="mb-2">
//             <a className="text-light" href="/help/copyright/">
//               Droits d'auteur
//             </a>
//           </li>
//           {/* <li className="mb-2" dangerouslySetInnerHTML = {{
//             __html: `
//               <a class="change-consent text-light" onclick="window.__cmp('displayConsentUi');">
//                 Paramètres de Gestion de la Confidentialité
//               </a>
//             `
//           }}>
//           </li> */}
//           <li className="mb-2">
//               {/* <a className="change-consent text-light" onClick={() => window.__cmp('displayConsentUi')}>
//                 Paramètres de Gestion de la Confidentialité
//               </a> */}
//               <a className="text-light" href="javascript: Cookiebot.renew()">
//                 Paramètres de Cookies et Gestion de la Confidentialité
//               </a>
//           </li>

//       </ul>
//     </div>
//   </div>
// </div>
// )
// const FR = () => (
//   <div className="container-fluid">

//   <div className="row">

//     <div className="col-sm-12 col-md-4">
//       <h3 className="text-white mb-2"> Explorer </h3>
//       <ul className="list-unstyled">
//         <li className="mb-2">
//         <li className="mb-2">
//           <Link to="/collections/films-gratuits/" className="text-light">
//             Films gratuits
//           </Link>
//         </li>
//           <Link to="/collections/vieux-films-gratuits/" className="text-light">
//             Vieux films gratuits en Français
//           </Link>
//         </li>
//         <li className="mb-2">
//           <Link to="/collections/documentaires-gratuits/" className="text-light">
//             Documentaires gratuits en Français
//           </Link>
//         </li>

//         <li className="mb-2">
//           <Link to="/collections/dessins-animes-gratuits/" className="text-light">
//             Dessins animés gratuits
//           </Link>
//         </li>
//       </ul>

//     </div>
//     <div className="col-sm-12 col-md-4">
//         <h3>
//           <Link to="/blog/" className="text-white">Blog</Link>
//         </h3>
//         <ul className="list-unstyled">
//           <li className="mb-2">
//             <Link to="/blog/films-gratuits/" className="text-light">
//               165 Films Gratuits en Français à Voir en Streaming
//             </Link>
//           </li>

//           <li className="mb-2">
//             <Link to="/blog/dessins-animes-gratuits/" className="text-light">
//               97 Dessins Animés Gratuits
//             </Link>
//           </li>
//         </ul>

//     </div>

//     <div className="col-sm-12 col-md-4 pr-0">
//       <h3 className="text-white mb-2"> Pages </h3>
//       <ul className="list-unstyled">

//           {/* <li className="mb-2">
//             <a className="text-light" href="https://cinetimes.org/apropos/">
//               À propos
//             </a>
//           </li> */}
//           <li className="mb-2">
//             <a className="text-light" href="/help/mentions-legales/">
//               Mentions légales et politique de confidentialité
//             </a>
//           </li>
//           <li className="mb-2">
//             <a className="text-light" href="/help/faq/">
//               F.A.Q
//             </a>
//           </li>
//           <li className="mb-2">
//             <a className="text-light" href="/help/copyright/">
//               Droits d'auteur
//             </a>
//           </li>
//           {/* <li className="mb-2" dangerouslySetInnerHTML = {{
//             __html: `
//               <a class="change-consent text-light" onclick="window.__cmp('displayConsentUi');">
//                 Paramètres de Gestion de la Confidentialité
//               </a>
//             `
//           }}>
//           </li> */}
//           <li className="mb-2">
//               {/* <a className="change-consent text-light" onClick={() => window.__cmp('displayConsentUi')}>
//                 Paramètres de Gestion de la Confidentialité
//               </a> */}
//               <a className="text-light" href="javascript: Cookiebot.renew()">
//                 Paramètres de Cookies et Gestion de la Confidentialité
//               </a>
//           </li>

//       </ul>
//     </div>
//   </div>
// </div>
// )

const Footer = () => {
  const { t } = useTranslation();
  const lang = t("iso");

  return (
    <footer className=" mt-4">
      <div id="footer_nav">
        <div className="fContainer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                {lang === "fr" && (
                  <>
                    {/* <h3 className="text-white mb-2"> <Trans>Genres</Trans> </h3> */}
                    {/* <div>
                <Link to="/search?query=France" className="btn btn-sm btn-outline-light mr-1 mb-1">Films Made in France</Link>
                <Link to="/search?query=Jean Gabin" className="btn btn-sm btn-outline-light mr-1 mb-1">Jean Gabin</Link>
                <Link to="/search?query=Erotique" className="btn btn-sm btn-outline-light mr-1 mb-1">Erotique</Link>
                <Link to="/search?query=Louis de Funes" className="btn btn-sm btn-outline-light mr-1 mb-1">Louis de Funes</Link>
                <Link to="/search?query=Western" className="btn btn-sm btn-outline-light mr-1 mb-1">Western</Link>
                <Link to="/search?query=Bourvil" className="btn btn-sm btn-outline-light mr-1 mb-1">Bourvil</Link>
                <Link to="/search?query=Belmondo" className="btn btn-sm btn-outline-light mr-1 mb-1">Belmondo</Link>
                <Link to="/search?query=Horreur" className="btn btn-sm btn-outline-light mr-1 mb-1">Horreur</Link>
                <Link to="/search?query=Animation" className="btn btn-sm btn-outline-light mr-1 mb-1">Dessins Animés</Link>
                <Link to="/search?query=Tintin" className="btn btn-sm btn-outline-light mr-1 mb-1">Tintin</Link>
                <Link to="/search?query=Sherlock Holmes" className="btn btn-sm btn-outline-light mr-1 mb-1">Sherlock Holmes</Link>
                <Link to="/search?query=Sexy" className="btn btn-sm btn-outline-light mr-1 mb-1">Sexy</Link>
                <Link to="/search?query=Hitchcock" className="btn btn-sm btn-outline-light mr-1 mb-1">Hitchcock</Link>
                <Link to="/search?query=Guerre" className="btn btn-sm btn-outline-light mr-1 mb-1">Guerre</Link>
                <Link to="/search?query=Nudité" className="btn btn-sm btn-outline-light mr-1 mb-1">Nudité</Link>
                <Link to="/search?query=Science Fiction" className="btn btn-sm btn-outline-light mr-1 mb-1">Science Fiction</Link>
                <Link to="/search?query=Chaplin" className="btn btn-sm btn-outline-light mr-1 mb-1">Charlie Chaplin</Link>
                <Link to="/search?query=John Wayne" className="btn btn-sm btn-outline-light mr-1 mb-1">John Wayne</Link>
                <Link to="/search?query=Nudité Féminine" className="btn btn-sm btn-outline-light mr-1 mb-1">Nudité Féminine</Link>

                <Link to="/search?query=Documentaire" className="btn btn-sm btn-outline-light mr-1 mb-1">Documentaires</Link>
                <Link to="/search?query=Mystère" className="btn btn-sm btn-outline-light mr-1 mb-1">Mystère</Link>
                <Link to="/search?query=Thriller" className="btn btn-sm btn-outline-light mr-1 mb-1">Thriller</Link>
                <Link to="/search?query=Crime" className="btn btn-sm btn-outline-light mr-1 mb-1">Crime</Link>
                <Link to="/search?query=Histoire" className="btn btn-sm btn-outline-light mr-1 mb-1">Historique</Link>
                <Link to="/search?query=Comédie" className="btn btn-sm btn-outline-light mr-1 mb-1">Comédies</Link>
                <Link to="/search?query=Muet" className="btn btn-sm btn-outline-light mr-1 mb-1">Muet</Link>
                <Link to="/search?query=Noir et Blanc" className="btn btn-sm btn-outline-light mr-1 mb-1">Noir et Blanc</Link>
                <Link to="/search?query=Drame" className="btn btn-sm btn-outline-light mr-1 mb-1">Drame</Link>


                <Link to="/collections/vieux-films-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Vieux films</Link>
                <Link to="/collections/action-aventure-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Action/Aventure</Link>
                <Link to="/collections/films-gratuits/" className="btn btn-sm btn-outline-light mr-1 mb-1">Tout</Link>
              </div> */}
                    {/* <ul className="list-unstyled">
                <li className="mb-2">
                  <Link to="/collections/vieux-films-gratuits/" className="text-light">
                    Vieux films
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/collections/documentaires-gratuits/" className="text-light">
                    Documentaires
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/collections/dessins-animes-gratuits/" className="text-light">
                    Dessins Animés
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/collections/action-aventure-gratuits/" className="text-light">
                    Action/Aventure
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/collections/films-horreur-gratuits/" className="text-light">
                    Horreur
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/collections/mystere-thriller-gratuits/" className="text-light">
                    Mystère/Thriller
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/collections/westerns-gratuits/" className="text-light">
                    Westerns
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/collections/films-gratuits/" className="text-light">
                    Tout
                  </Link>
                </li>
              </ul> */}
                    <h3 className="text-white mb-2">
                      {" "}
                      <a
                        href="https://cinetimes.org/blog/"
                        className="text-light"
                      >
                        {" "}
                        <Trans>Blog</Trans>{" "}
                      </a>
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a
                          href="https://cinetimes.org/blog/films-gratuits/"
                          className="text-light"
                        >
                          165 Films Gratuits en Français à Voir en Streaming
                        </a>
                      </li>

                      <li className="mb-2">
                        <a
                          href="https://cinetimes.org/blog/dessins-animes-gratuits/"
                          className="text-light"
                        >
                          97 Dessins Animés Gratuits (à voir en streaming)
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://cinetimes.org/blog/films-d-horreur-gratuits/"
                          className="text-light"
                        >
                          Regarder gratuitement des Films d'horreur en streaming
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://cinetimes.org/blog/films-gratuits-sur-youtube/"
                          className="text-light"
                        >
                          Films gratuits sur YouTube, classés et ordonnés
                        </a>
                      </li>
                    </ul>
                  </>
                )}
                {lang === "es" && (
                  <>
                    <h3 className="text-white mb-2">
                      <a
                        href="https://cinetimes.org/es/blog/"
                        className="text-light"
                      >
                        {" "}
                        <Trans>Blog</Trans>
                      </a>{" "}
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a
                          href="https://cinetimes.org/lat/blog/peliculas-completas-espanol-latino/"
                          className="text-light"
                        >
                          Películas completas en español latino
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://cinetimes.org/es/blog/peliculas-gratuitas-espanol/"
                          className="text-light"
                        >
                          Películas completas en español
                        </a>
                      </li>
                    </ul>
                  </>
                )}
                {lang === "lat" && (
                  <>
                    <h3 className="text-white mb-2">
                      <a
                        href="https://cinetimes.org/lat/blog/"
                        className="text-light"
                      >
                        {" "}
                        <Trans>Blog</Trans>
                      </a>{" "}
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a
                          href="https://cinetimes.org/lat/blog/peliculas-completas-espanol-latino/"
                          className="text-light"
                        >
                          Películas completas en español latino
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://cinetimes.org/es/blog/peliculas-gratuitas-espanol/"
                          className="text-light"
                        >
                          Películas completas en español
                        </a>
                      </li>
                    </ul>
                  </>
                )}
                {lang === "en" && (
                  <>
                    <h3 className="text-white mb-2"> New </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <Link
                          to="/t/max-fleischers-superman-1941-1942"
                          className="text-light"
                        >
                          Superman - Max Fleischer
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/elephants-dream_hqov"
                          className="text-light"
                        >
                          Elephants Dream - Open Blender Studios
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/t/unconquered" className="text-light">
                          Unconquered - Gary Cooper
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/t/sintel_gaw7" className="text-light">
                          Sintel - Open Blender Studios
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="" className="text-light">
                          Tammy and the Millionaire - Sidney Miller
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <div className="col-sm-12 col-md-4">
                {lang === "fr" && (
                  <>
                    <h3>
                      <Link to="/" className="text-white">
                        <Trans>Populaire</Trans>
                      </Link>
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <Link to="/t/lenfant-sauvage" className="text-light">
                          L'Enfant sauvage - François Truffaut
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/the-kid-charlie-chaplin-francais"
                          className="text-light"
                        >
                          Le Kid (The Kid) - Charlie Chaplin
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/morgane-et-ses-nymphes"
                          className="text-light"
                        >
                          Morgane et ses nymphes - Bruno Gantillon
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/la-derniere-femme-sur-terre"
                          className="text-light"
                        >
                          La Dernière Femme Sur Terre - Roger Corman
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/le-clan-des-siciliens"
                          className="text-light"
                        >
                          Le Clan des Siciliens - Henri Verneuil
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
                {lang === "en" && (
                  <>
                    <h3>
                      <Link to="/" className="text-white">
                        Popular
                      </Link>
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <Link
                          to="/t/fathers-little-dividend"
                          className="text-light"
                        >
                          {" "}
                          Father's Little Dividend - Spencer Tracy
                        </Link>
                      </li>

                      <li className="mb-2">
                        <Link to="/t/the-lady-vanishes" className="text-light">
                          {" "}
                          The Lady Vanishes - Alfred Hitchcock
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/t/of-human-bondage" className="text-light">
                          {" "}
                          Charade - Cary Grant{" "}
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/t/12-angry-men" className="text-light">
                          {" "}
                          12 Angry Men - Sidney Lumet
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/last-woman-on-earth"
                          className="text-light"
                        >
                          {" "}
                          Last Woman on Earth - Roger Corman
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
                {lang === "es" && (
                  <>
                    <h3>
                      <Link to="/" className="text-white">
                        Popular
                      </Link>
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <Link to="/t/charada" className="text-light">
                          {" "}
                          Charada - Cary Grant{" "}
                        </Link>
                      </li>

                      <li className="mb-2">
                        <Link
                          to="/t/carta-de-una-desconocida"
                          className="text-light"
                        >
                          Carta de una desconocida - Max Ophüls
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/serenata-nostalgica"
                          className="text-light"
                        >
                          {" "}
                          Serenata nostálgica - Cary Grant{" "}
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/t/ariane-30-06-1957" className="text-light">
                          {" "}
                          Ariane - Audrey Hepburn
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/t/bodas-reales" className="text-light">
                          {" "}
                          Bodas reales - Jane Powell{" "}
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
                {lang === "lat" && (
                  <>
                    <h3>
                      <Link to="/" className="text-white">
                        Popular
                      </Link>
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <Link
                          to="/t/un-cuento-de-navidad"
                          className="text-light"
                        >
                          {" "}
                          Un cuento de Navidad{" "}
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/jason-y-los-argonautas"
                          className="text-light"
                        >
                          {" "}
                          Jasón y los argonautas{" "}
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/el-libro-de-la-vida"
                          className="text-light"
                        >
                          {" "}
                          El libro de la vida{" "}
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/t/par-impar" className="text-light">
                          {" "}
                          Par-Impar{" "}
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          to="/t/nosotras-las-taquigrafas-28-12-1950"
                          className="text-light"
                        >
                          {" "}
                          Nosotras, las taquígrafas{" "}
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </div>

              <div className="col-sm-12 col-md-4 pr-0">
                <h3 className="text-white mb-2">
                  {" "}
                  <Trans>Pages</Trans>{" "}
                </h3>
                <ul className="list-unstyled">
                  {/* <li className="mb-2">
                  <a className="text-light" href="https://cinetimes.org/apropos/">
                    À propos
                  </a>
                </li> */}
                  <li className="mb-2">
                    <Link className="text-light" to="/help/mentions-legales/">
                      <Trans>
                        Mentions légales et politique de confidentialité
                      </Trans>
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="text-light" to="/help/faq/">
                      <Trans>F.A.Q</Trans>
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="text-light" to="/help/copyright/">
                      <Trans>Droits d'auteur</Trans>
                    </Link>
                  </li>
                  {/* <li className="mb-2" dangerouslySetInnerHTML = {{
                  __html: `
                    <a class="change-consent text-light" onclick="window.__cmp('displayConsentUi');">
                      Paramètres de Gestion de la Confidentialité
                    </a>
                  `
                }}>
                </li> */}
                  <li className="mb-2">
                    {/* <a className="change-consent text-light" onClick={() => window.__cmp('displayConsentUi')}>
                      Paramètres de Gestion de la Confidentialité
                    </a> */}
                    <a
                      className="text-light"
                      href="javascript: Cookiebot.renew()"
                    >
                      <Trans>
                        Paramètres de Cookies et Gestion de la Confidentialité
                      </Trans>
                    </a>
                  </li>
                </ul>
                {lang === "en" && (
                  <>
                    <h3 className="text-white mb-2"> Useful links </h3>
                    <ul className="list-unstyled">
                      {/* <li className="mb-2">
                  <a href="https://blog.cinetimes.org/en/" target="_blank" className="text-light"> Blog </a>
                </li> */}
                      <li className="mb-2">
                        <a
                          href="https://proximovie.com"
                          target="_blank"
                          className="text-light"
                        >
                          {" "}
                          Proximovie - What to watch next{" "}
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://www.websitehunt.co"
                          target="_blank"
                          className="text-light"
                        >
                          {" "}
                          Websitehunt - Best websites you didn't know{" "}
                        </a>
                      </li>
                      {/* <li className="mb-2">
                  <a href="https://micaeldeprado.com" target="_blank" className="text-light"> Author's site </a>
                </li> */}
                    </ul>
                  </>
                )}
                {lang === "fr" && (
                  <>
                    <h3 className="text-white mb-2"> Liens utiles </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a
                          href="https://www.attentionauportefeuille.com/cadeaux/"
                          target="_blank"
                          className="text-light"
                        >
                          Guides de cadeaux
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://nombredemots.com"
                          target="_blank"
                          className="text-light"
                        >
                          {" "}
                          Compteur de mots{" "}
                        </a>
                      </li>
                    </ul>
                  </>
                )}
                {lang === "es" && (
                  <>
                    <h3 className="text-white mb-2">
                      {" "}
                      Herramientas y enlaces{" "}
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a
                          href="https://www.alternativas.io/cinetimes"
                          target="_blank"
                          className="text-light"
                        >
                          {" "}
                          Alternativas a Cinetimes{" "}
                        </a>
                      </li>
                    </ul>
                  </>
                )}
                {lang === "lat" && (
                  <>
                    <h3 className="text-white mb-2">
                      {" "}
                      Herramientas y enlaces{" "}
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a
                          href="https://www.alternativas.io/cinetimes"
                          target="_blank"
                          className="text-light"
                        >
                          {" "}
                          Alternativas a Cinetimes{" "}
                        </a>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footerBottom">
        <p>
          {" "}
          Copyright &copy; 2021 Cinetimes ·
          {/* <a href="https://nombredemots.fr" target="_blank" className="text-light"> Compteur de mots </a> ·  */}
          {/* <a href="https://proximovie.com" target="_blank" className="text-light"> Explore similar movies </a>  */}
        </p>
        <p> </p>
        <p>
          <a
            className="fab fa-twitter fa-2x text-light"
            href="https://twitter.com/CinetimesFR"
            target="_blank"
            rel="nofollow noopener noreferrer"
          ></a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
